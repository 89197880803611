<script>
/* import example from "../assets/uploads/img/r2.png"; */
import NoImage from "../assets/no-image.jpg";
import Message from "ant-design-vue/lib/message";
import { mapGetters } from "vuex";
// import { component } from "vue/types/umd";

export default {
  name: "NewCard",

  props: ["product", "title", "points", "description", "image", "is_small"],
  data() {
    return {
      noImage: NoImage,
    };
  },
  methods: {
    checkUserPoints(productPoints) {
      if (productPoints > this.userPoints) {
        return false;
      } else return true;
    },
    cartLimitReached(productPoints) {
      const combinedTotal = productPoints + this.cartPoints;
      if (combinedTotal >= this.userPoints) {
        return true;
      } else return false;
    },
    getDetails(id) {
      this.$router.push({ name: "SingleProduct", params: { productId: id } });
    },
    addToCart() {
      const quantity = 1;
      const points = this.product.points;
      if (this.loggedIn) {
        if (this.cartLimitReached(points)) {
          Message.warning(
            "We apologize, your point balance is insufficient to order this Reward."
          );
          return;
        }
        const canAdd = this.checkUserPoints(points);
        if (!canAdd) {
          Message.warning("Not enough points!");
          return;
        }
        this.$store
          .dispatch("Cart/addToCart", {
            product: this.product,
            quantity: quantity,
          })
          .then(() => {
            Message.loading("Adding Item to cart...", 1).then(() => {
              Message.success("Item added to your cart! :)");
            });
          })
          .catch((e) => {
            if (e.response.status === 404) {
              Message.info(e.response.data.message);
            } else if (e.response.status == 500) {
              Message.info("Oops bumped into an error");
            } else if (e.response.status == 401) {
              Message.info("User not logged in.");
              localStorage.removeItem("next");
              this.$router.push({ name: "Login" });
            }
          });
      } else {
        Message.info("Login required first.");

        // must unset the stored path
        localStorage.removeItem("next");

        this.$router.push({
          name: "Login",
        });
      }
    },
    // addToCart() {},
  },
  computed: {
    ...mapGetters({
      cartPoints: "Cart/cartTotalPoints",
      userPoints: "Auth/userPoints",
    }),
    loggedIn() {
      return this.$store.state.Auth.token;
    },
  },
};
</script>
<template>
  <div class="new-card" @click="getDetails(product.id)">
    <template v-if="image != null">
      <img :src="image" alt="No Image" class="new-card__img" />
    </template>
    <template v-else>
      <img :src="noImage" alt="" class="new-card__img" />
    </template>
    <div class="new-card__title">
      {{ title }}
    </div>
    <div class="new-card__points-area">
      <div class="new-card__points-area--points">{{ points }} Points</div>
      <a class="new-card__points-area--add-to-cart " @click.stop="addToCart"
        >Add to cart</a
      >
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../assets/styles/_mixins.scss";
@import "../assets/styles/_components.scss";
@import "../assets/styles/_utilities.scss";
.new-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 7.6rem;
  padding: 1.4rem;
  width: 22rem;
  cursor: pointer;
  transition: box-shadow 0.4s ease, border 0.4s ease;
  &__img {
    // width: 18rem;
    height: 20rem;
  }
  &__title {
    font-family: var(--open-sans);
    padding: 3.2rem 0;

    font-size: 1.7rem;
  }
  &__points-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--points {
      color: var(--color-grey-dark-2);
      font-family: var(--open-sans);
      font-size: 1.5rem;
      // margin-right: 0.7rem;
    }
    &--add-to-cart {
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.3rem;
    }
  }
  // &:not(:first-child) {
  //   margin-left: 10.4rem;
  // }
  &:hover {
    background: #fff;
    // padding: 2rem;
    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.12);
    border: 0.03rem solid rgba(0, 0, 0, 0.1);
    .new-card__title {
      color: var(--color-lapis-blue);
    }
  }
  @include respond(phone) {
    margin-right: 0;
  }
}
</style>
