<script>
// import Vue from 'vue'
import { mapActions } from "vuex";
import NoImageJPG from "@/assets/no-image.jpg";
import example1 from "@/assets/r10-172x300.jpg";
import example2 from "@/assets/r2.png";
import example3 from "@/assets/r9-223x300.jpg";
import example4 from "@/assets/tuning-fork-1902632_640.jpg";
import NewCard from "@/components/NewCard.vue";
import Text from "@/assets/new_text.png";
import Pagination from "ant-design-vue/lib/pagination";
import Loader from "vue-element-loading";
import Message from "ant-design-vue/lib/message";
import Notif from "ant-design-vue/lib/notification";
import AIcon from "ant-design-vue/lib/icon";

export default {
  components: {
    Pagination,
    // AIcon,
    // ProductCard,
    // Message,
    NewCard,
    Loader,
  },
  data() {
    return {
      img1: example1,
      img2: example2,
      img3: example3,
      img4: example4,
      mainTxt: Text,
      pagination: {
        pageSize: 8,
      },
      defaultPageSize: 8,
      defaultPage: 1,
      currentPage: 1,
      pageSize: 8,
      pageLoading: false,
      productsLoading: false,
      featuredProductsLoading: false,
      firstTimerWelcomeNotif: true,
    };
  },
  watch: {
    // firstTimer() {
    //   if (this.$store.state.Auth.isFirstTime) {
    //     this.$notification.open({
    //       message: "Welcome!!",
    //       description: `Welcome to your rewards shop, where you don't need any money to shop.  Earn points every time you order from us on Discogs, and spend those points here on rewards!  Your orders with us have earned you ${this.points} points!`,
    //     });
    //   }
    // },
  },
  methods: {
    ...mapActions({
      loadProductsAction: "Product/loadProducts",
      loadFeaturedProductsAction: "Product/loadFeatured",
    }),
    firstTimer() {
      if (
        this.$store.state.Auth.isFirstTime &&
        this.firstTimerWelcomeNotif == true
      ) {
        Notif.open({
          message: "Dig In!!",
          description: `Welcome to your rewards shop, where you don't need any money to shop.  Earn points every time you order from us on Discogs, and spend those points here on rewards!  Your orders with us have earned you ${this.points} points!`,
          icon: <AIcon type="smile" style="color: #108ee9" />,
        });
        this.firstTimerWelcomeNotif = false;
      }
    },
    getImage(product) {
      if (product.images && product.images.length) {
        return product.images[0].imageUrl;
      }

      return NoImageJPG;
    },
    getShortTitle(title, limit) {
      if (!title) {
        return title;
      }
      let shortTitle = null;
      if (!limit) {
        const limit = 120;
        if (title.length > limit) {
          shortTitle = title.substr(0, limit) + "...";
          return shortTitle;
        }
      }

      // let limit = 26;
      if (title.length > limit) {
        shortTitle = title.substr(0, limit) + "...";
        return shortTitle;
      }
      return title;
    },
    getShortDesc(desc) {
      if (!desc) {
        return desc;
      }

      const limit = 80;
      if (desc.length > limit) {
        const s = desc.substr(0, limit) + "...";
        return s;
      }
      return desc;
    },
    cardClicked(id) {
      this.$router.push({ name: "SingleProduct", params: { productId: id } });
    },
    loadProducts(page, itemsPerPage) {
      this.pageLoading = true;
      if (!page) {
        page = this.defaultPage;
      } else {
        this.currentPage = page;
      }
      if (!itemsPerPage) {
        itemsPerPage = this.defaultPageSize;
      } else {
        this.defaultPageSize = itemsPerPage;
      }
      this.loadProductsAction({ page: page, itemsPerPage: itemsPerPage })
        .then(() => {
          Message.success("Products Loaded Enjoy!");
        })
        .catch((err) => {
          console.log(err);
          this.productsLoading = false;
        });
      // this.$store.dispatch("Product/loadProducts", {
      //   page: page,
      //   itemsPerPage: itemsPerPage,
      // });
      // this.productsLoading = false;
    },
    loadFeaturedProducts() {
      this.featuredProductsLoading = true;
      this.loadFeaturedProductsAction()
        .then(() => {
          Message.success("Featured items loaded, take a look!");
          this.featuredProductsLoading = false;
        })
        .catch(() => {
          this.featuredProductsLoading = false;
        });
      // this.$store.dispatch("Product/loadFeatured");
      // this.featuredProductsLoading = false;
    },
    openNotif() {
      this.$notification.open({
        message: "Welcome!!",
        description: `Welcome to your rewards shop, where you don't need any money to shop.  Earn points every time you order from us on Discogs, and spend those points here on rewards!  Your orders with us have earned you ${this.points} points!`,
      });
    },
  },
  computed: {
    products() {
      return this.$store.state.Product.products;
    },
    featuredProducts() {
      return this.$store.state.Product.featuredProducts;
    },
    productsCount() {
      return this.$store.getters["Product/productsCount"];
    },
    points() {
      return this.$store.state.Auth.points;
    },
    // isFirstTime()
  },
  created() {
    this.loadProducts();
    this.loadFeaturedProducts();
    this.$store.state.Nav.page = null;
    this.firstTimer();
  },
  // mounted() {
  //   this.$notification.success("Welcome Back!");
  // },
};
</script>

<template>
  <div class="home">
    <!-- <div class="header"> -->
    <div class="hero">
      <div class="hero-text-area">
        <img :src="mainTxt" alt="" class="main-text" />
        <div class="subtext">
          <p>
            We feel it's not enough to simply say 'Thank You'. We prefer to
            demonstrate our gratitude. Every time you complete an order with us
            on Discogs or eBay, you earn points. You can now redeem these points
            here for cool items we think you'll like.
          </p>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- <div class="content"> -->
    <!-- <div class="my-container"> -->
    <div class="section my-container">
      <h1 class="section__title">This Month's Offers</h1>
      <h4 class="section__subtitle">Hottest Offers!!</h4>
      <div class="section__content">
        <Loader :active="featuredProductsLoading" spinner="ring" />
        <new-card
          v-for="product in featuredProducts"
          :key="product.id"
          :isSmall="false"
          :product="product"
          :title="getShortTitle(product.title)"
          :description="getShortDesc(product.description)"
          :points="product.points"
          :image="product.main_image"
        />
        <!-- <product-card
          v-for="product in featuredProducts"
          :key="product.id"
          :isSmall="false"
          :product="product"
          :title="getShortTitle(product.title)"
          :description="getShortDesc(product.description)"
          :points="product.points"
          :image="product.main_image"
        /> -->
      </div>
    </div>
    <div class="section tight" id="rewards">
      <h1 class="section__title">Reward Items</h1>
      <h4 class="section__subtitle">All avaiable items</h4>
      <div class="section__content">
        <Loader :active="productsLoading" spinner="ring" />
        <new-card
          v-for="product in products"
          :key="product.id"
          :isSmall="true"
          :product="product"
          :title="getShortTitle(product.title)"
          :description="getShortDesc(product.description)"
          :points="product.points"
          :image="product.main_image"
        />
      </div>
      <div class="pagination">
        <pagination
          :total="productsCount"
          :show-total="(total) => `Total ${total} items`"
          :page-size.sync="pageSize"
          :current="currentPage"
          :default-current="defaultPage"
          @change="
            (currentPage, pageSize) => loadProducts(currentPage, pageSize)
          "
        />
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
    <div class="footer">
      <div class="footer__top">
        <div
          class="footer__top__logo"
          @click.prevent="$router.push({ name: 'Home' })"
        >
          <img src="../assets/new_logo.png" alt="kupiku logo" class="img" />
        </div>
        <div class="footer__top__links">
          <router-link :to="{ name: 'TermsOfUse' }">Terms of Use</router-link>
          <!-- TODO: Add text for this -->
          <!-- <router-link :to="{ name: 'PrivacyPolicy' }"
            >Privacy Policy</router-link
          > -->
          <router-link :to="{ name: 'AboutUs' }">About Us</router-link>
          <router-link :to="{ name: 'ContactUs' }">Contact Us</router-link>
          <a href="" class="mr-0">Blog</a>
        </div>
      </div>
      <div class="footer__end">
        <p class="footer__end__address">
          &copy; 2021, Kupiku AG, Dammstrasse 19, 6300 Zug, Switzerland
          (CHE-218.847.356)
        </p>
        <div class="footer__payment-logos"></div>
      </div>
      <!-- <h4>Kupiku &copy; 2020</h4> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../styles/_mixins.scss";
@import "../assets/styles/_components.scss";
@import "../assets/styles/_utilities.scss";

.my-container {
  // margin: 0 9rem;
  // position: relative;
  @include respond(tab_l) {
  }
  @include respond(tab_p) {
  }
  @include respond(phone) {
    margin: 0;
  }
}
.home {
  position: relative;
  width: 100%;
  background: var(--white);
}

// .header {
// position: relative;
// width: 100%;
// height: 100%;
.hero {
  position: relative;
  // top: 0;
  margin-top: 0rem;
  height: 85vh;
  background-image: url("../assets/bg-blur-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // position: relative;
  z-index: 0;
  .hero-text-area {
    position: relative;
    // max-height: 270px;
    // top: 190px;
    margin-top: 3rem;
    width: 80rem;
    position: relative;
    // font-size: 0;
    background: rgba(0, 0, 0, 0.7);
    padding: 1rem 2.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 0;
    // margin-top: 70px;
    .main-text {
      // padding: 0 !important;
      // margin-top: -120px;
      // text-align: center;
      // min-height:300px;
      // width: 00px;
      position: relative;
      z-index: 0;
      @include respond(phone) {
        width: 240px;
      }
    }
    .subtext {
      font-family: "Hind Madurai", sans-serif;
      text-align: center;
      // margin-top: -200px;
      padding: 0.5rem;
      letter-spacing: 0.2rem;
      font-weight: 300;
      font-size: 1.7rem;
      width: 670px;
      color: #aa9f9f;
      position: relative;
      z-index: 0;
      @include respond(phone) {
        width: 30rem;
      }
    }
    @include respond(phone) {
      width: 30rem;
    }
  }
}

////////////////////////////////////
/////// CONTENT AREA

.section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // position: relative;
  // align-items: center;
  // padding: 3.4rem;
  margin-top: 3.2rem;
  background: inherit;

  &__title {
    align-items: center;
    // font-family: "Bangers", sans-serif;
    // font-family: var(--kalam);
    font-size: 2.7em;
    font-weight: 300;
    line-height: 1.2;
    align-self: center;
    letter-spacing: 0.2em;
    color: #1f252e;
    text-align: center;
  }
  &__subtitle {
    // margin-top: -1rem;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 1.2em;
    font-weight: 300;
    color: var(--color-grey-dark-2);
    letter-spacing: 1.2em;
    text-transform: uppercase;
  }
  &__content {
    margin-top: 3.2rem;
    // padding: 6rem;
    // width: 78rem;
    margin-left: 7.6rem;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    @include respond(phone) {
      padding: 0 5.3rem;
      margin-top: 3.2rem;
      margin-left: 0;
    }
  }
}

.points-btn {
  padding: 1.1rem;
  border-radius: 2rem;
  background: #031927;
  box-shadow: 0 0.1rem 0.6rem rgba(0, 0, 0, 0.3);
}
.highlight {
  color: var(--color-primary-dark);
}

/////////////////////
/////// ITEM CARD
.card {
  // flex: 1 0 30%;
  // margin-right: 1.7rem;
  overflow: hidden;
  position: relative;
  background: #fff;
  height: 42rem;
  width: 30rem;
  margin-bottom: 1.9rem;
  margin-right: 2.6rem;
  border: 1.1px solid var(--color-grey-light-3);
  border-radius: 3rem;
  box-shadow: var(--small-shadow-light);
  transform: scale(0.95);
  transition: box-shadow 0.5s, transform 0.5s;
  cursor: pointer;
  &.small {
    height: 30rem;
    width: 23rem;
    border-radius: 1.2em;
    margin-bottom: 1.4em;
    z-index: 1;
    .card__image {
      height: 62%;
    }
    .card__content {
      &__title {
        font-size: 2.3rem;
        margin-top: -1.2em;
      }
      &__right {
        .icon {
          font-size: 5.8em;
        }
      }
    }
    .card__content_points {
      &__points-icon {
        display: none;
      }
    }
    .details {
      &:hover {
        height: 100%;
      }
    }
  }
  &__image {
    position: relative;
    z-index: 2;
    min-height: 70%;
    width: 100%;
  }
  &__content {
    z-index: 2;
    position: relative;
    display: flex;
    height: 100%;

    &__left {
      padding: 2.4rem;
      border-right: var(--line);

      flex: 1 0 35%;
      overflow: hidden;
    }
    &__right {
      flex: 1;
      display: flex;
      justify-content: center;
      .icon {
        position: absolute;
        font-size: 2.4rem;
        top: 4rem;
        flex: 0;
        transition: transform 0.5s;
      }
      &:hover {
        background: var(--color-lapis-blue-hover);
      }
      &:hover .icon {
        transform: translateY(0.19em);
        color: #00394b;
      }
    }
    &__title {
      // font-family: "Rubik", sans-serif;
      font-family: var(--marker);
      font-size: 2rem;
    }
    &__points {
      font-family: "Source Sans", sans-serif;
      font-size: 1.8em;
    }
  }
  .clicked {
    .card__content {
      &__left {
        // padding: inherit;
        border-right: var(--line);
        flex: 1;
      }
      &__right {
      }
    }
  }
  .details {
    overflow: hidden;
    background: var(--color-lighter-teal);
    box-shadow: var(--small-shadow-light);
    position: absolute;
    z-index: 3;
    height: 14rem;
    width: 14.6rem;
    top: -7rem;
    right: -7rem;
    border-radius: 30rem 0 0 40rem;
    transition: all 0.3s, border-radius 0.2s, top 0.2s;
    .icon {
      color: var(--color-primary-dark);
      font-size: 1.5rem;
      position: absolute;
      right: 9rem;
      top: 9rem;
    }
    &:hover {
      width: 100%;
      right: 0;
      top: 0;
      height: 70%;
      border-radius: 0;
      .icon {
        color: var(--color-blue-primary-dark);
        display: none;
      }
      .details__body {
        opacity: 1;
        padding: 3rem;
        color: var(--color-primary-dark);
        position: relative;
        align-items: center;
        transform: scale(1);
        transform: translateY(0);
        .title {
          font-family: "Rubik", sans-serif;
          font-size: 2.1rem;
          color: #fff;
        }
        .info {
          font-family: "Source Sans Pro", sans-serif;
          font-size: 1.8em;
        }
      }
    }
    &__body {
      opacity: 0;
      align-items: center;
      color: #fff;
      transform: scale(0.5);
      transform: translateY(-200%);
      transition: opacity 0.1s, transform 0.5s;
    }
  }

  &:hover {
    transform: scale(1);
    box-shadow: var(--small-shadow-light-darker);
  }
}

//////////////////////
///////// REWARDS CARD
.tight {
  padding: 0 5.3rem;
  margin-top: 3.2rem;
}
.reward-card {
  overflow: hidden;
  position: relative;
  background: #fff;
  height: 25rem;
  width: 20rem;
  border: 1.2px solid var(--color-grey-light-3);
  box-shadow: var(--small-shadow-light);
  z-index: 1;
  cursor: pointer;
}

///////////////////////////////
//////////DIVIDER
.divider {
  border: 8px solid orangered;
}

//////////////////////////////
///////////ICON
.points-icon {
  padding-right: 0.6em;
  font-size: 1.8rem;
  color: var(--color-lapis-blue);
}

/////////////////////////////////
////////////////////ICON RIGHT
.icon {
  margin-right: 0.5em;
}

.pnt-icon {
  margin-right: 0.5em;
  color: var(--color-deep-yellow);
}

//////////////////////////////
//////////// FOOTER
.footer {
  margin-top: 3.1rem;
  // height: 6.5rem;
  background: rgba(0, 0, 0, 1);
  background-image: url("../assets/footer-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top: 0.2em solid var(--color-grey-light-2);
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  // align-items: center;
  padding: 0 9rem;
  h4 {
    color: var(--color-grey-light-3);
  }
  &__top {
    display: flex;
    justify-content: space-between;
    &__logo {
      cursor: pointer;
      .img {
        height: 6rem;
        width: 6rem;
      }
    }
    &__links {
      align-self: center;
      a {
        text-decoration: none;
        margin-right: 7.8rem;
        color: var(--white);
        font-size: 1.4rem;
        transition: color 0.2s ease;
        &:hover {
          color: var(--color-lapis-blue);
        }
      }
    }
  }
  &__end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &__address {
      color: var(--color-grey-light-4);
      padding-top: 1rem;
    }
  }
  @include respond(phone) {
    .footer {
      &__top {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &__links {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 0;
          // align-items: center;
          a {
            padding: 1rem;
            font-size: 1.6rem;
            margin-right: 0;
          }
        }
      }
    }
  }
}
.pagination {
  margin: 4.2rem 0 0 0;
}
</style>
